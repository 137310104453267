import React, { useEffect } from 'react';
import './App.css';
import ShowTime from './ShowTime'
import PrepRoom from './PrepRoom'
import * as api from './api'
import Introduction from './Introduction'

function App() {
    const [freestyle, setFreestyle] = React.useState<api.Freestyle | null>(null)
    return (
        <div className="App">
            <PrepRoom setFreestyle={setFreestyle} />
            {
                freestyle && (
                    <ShowTime freestyle={freestyle} key={freestyle.musicUrl} />
                )
            }
            <Introduction />
        </div>
    )
}

export default App;
