import React from 'react'
import wechatPNG from './wechat.jpg'
import wechat2PNG from './wechat2.jpg'
import './Introduction.css'

function Introduction() {
    return (
        <div className='introdution'>
            <hr />

            <h2>Why need Copilot-for-Demo?</h2>
            <ol>
                <li>Many hackers enjoy hackathons, except for demo part.</li>
                <li>Some hackers are not good at demo or don't have time to prepare.</li>
                <li>However, the demo part is very important.</li>
            </ol>

            <h2>What does Copilot-for-Demo provide?</h2>
            <ol>
                <li>Copilot can help you fully automatic demonstrate your project by inputting your project repository link.</li>
                <li>It displays your project in a unique way, making it more appealing.</li>
            </ol>

            <h2>What else can Copilot-for-Demo do?</h2>
            <ol>
                <li>Copilot can also generate <b>viral marketing promotional videos</b> for your product (to-do)!</li>
                <li>It can help make your product more popular on platforms like <b>Youtube, Bilibili, and Tiktok</b>!</li>
            </ol>

            <h2>Inspiration</h2>
            <img style={{ maxWidth: '300px' }} src={wechat2PNG} />
            <img style={{ maxWidth: '300px' }} src={wechatPNG} />

            <h2>Team</h2>
            <ol>
                <li><a href='https://bennhuang.com'>benn</a></li>
            </ol>

            <h2>Previous Works</h2>
            <ol>
                <li><a href='https://github.com/Bin-Huang/chatbox'>Chatbox</a></li>
                <li>Wheremylife.cn</li>
            </ol>

            <hr />
            <div style={{textAlign: 'center'}}>
                <p>Just for FUN!</p>
                <p><a href='https://bennhuang.com'>bennhuang.com</a></p>
                <p>2023 AI Hackathon</p>
            </div>
        </div>
    )

}

export default Introduction
