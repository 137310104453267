import React, { useEffect, useRef } from 'react';
import * as api from './api'
import cat1 from './cat1.png'
import cat2 from './cat2.png'
import cat0 from './cat0.png'
import './ShowTime.css'
import 'animate.css';

export interface Props {
    freestyle: api.Freestyle
}

function ShowTime(props: Props) {
    const [showNow, setShowNow] = React.useState(false)

    useEffect(() => {
        if (showNow) {
            document.querySelector('.show-time')?.scrollIntoView({ behavior: 'smooth' })
        }
    }, [showNow])

    const catRef = useRef<HTMLImageElement>(null)
    useEffect(() => {
        if (!catRef.current) {
            return
        }
        catRef.current.scrollIntoView({ behavior: 'smooth', inline: 'center' })
    }, [])

    const audioRef = useRef<HTMLAudioElement>(null)
    useEffect(() => {
        if (audioRef.current) {
            audioRef.current.addEventListener('ended', () => {
                setShowNow(false)
            })
        }

        // return () => {
        //     if (audioRef.current) {
        //         audioRef.current.removeEventListener('timeupdate', handleProgress);
        //     }
        // };
    }, []);

    const timer = useRef<NodeJS.Timeout | null>(null)
    const timerTick = useRef(0)

    useEffect(() => {
        if (!showNow) {
            if (timer.current) {
                clearInterval(timer.current)
            }
            timer.current = null
            timerTick.current = 0
            return
        }
        audioRef.current?.play()
        timer.current = setInterval(() => {
            handleProgress()
            timerTick.current += 50
        }, 50)
        return () => {
            audioRef.current?.pause()
            if (timer.current) {
                clearInterval(timer.current)
            }
            timer.current = null
            timerTick.current = 0
        }
    }, [showNow])

    const lyricDisplayRef = useRef<HTMLParagraphElement>(null)
    const lyricTranslatedDisplayRef = useRef<HTMLParagraphElement>(null)
    const backgroundRef = useRef<HTMLParagraphElement>(null)
    const backgroundTextRef = useRef<HTMLParagraphElement>(null)

    const handleProgress = () => {
        if (!audioRef.current) {
            return
        }
        const currentTime = audioRef.current?.currentTime * 22000
        console.log(currentTime)

        let line: api.Freestyle['lyricsLines'][0] | null = null
        let lineIndex = 0
        for (let i = 0; i < props.freestyle.lyricsLines.length; i++) {
            if (props.freestyle.lyricsLines[i].start <= currentTime) {
                line = props.freestyle.lyricsLines[i]
                lineIndex = i
            }
        }

        if (backgroundRef.current) {
            backgroundRef.current.className = backgroundRef.current.className.split(' ')[0] + ` stage${lineIndex}`
        }

        let backgroundWords: string[] = []
        let openMouth = false
        const lyricDisplay = line?.words.map((w, i) => {
            if (w.start <= currentTime) {
                backgroundWords.push(w.word)
            }
            // if (w.start <= currentTime && w.end >= currentTime + 3500) {
            if (w.start <= currentTime && w.end >= currentTime + 3000) {
                openMouth = true
            }
            if (w.start <= currentTime && w.end >= currentTime) {
                return `<b>${w.word}</b>`
            }
            return w.word
        }).join(' ')

        backgroundWords = backgroundWords.slice(backgroundWords.length - backgroundWords.length % 6)
        backgroundTextRef.current!.innerHTML = backgroundWords.join(' ')
        backgroundTextRef.current!.className = backgroundTextRef.current!.className.split(' ')[0] + ` backgroundText${lineIndex}`

        if (lyricDisplayRef.current) {
            lyricDisplayRef.current.innerHTML = lyricDisplay || '(Music~)'
        }
        if (lyricTranslatedDisplayRef.current) {
            lyricTranslatedDisplayRef.current.innerHTML = line?.translated ? `（${line?.translated}）` : '(音乐～)'
        }

        if (currentTime > 22000 * 5) {
            if (catRef.current) {
                catRef.current.src = openMouth ? cat2 : cat1
            }
        }
        // if (catRef.current?.src === cat2) {
        //     catRef.current.src = cat1
        // } else if (catRef.current) {
        // }
    };

    const play = () => {
        setShowNow(false)
        setShowNow(true)
    }

    return (
        <div className={`show-time ` + (showNow ? 'show-now' : '')}>
            <audio ref={audioRef} src={props.freestyle.musicUrl} controls hidden autoPlay={false} />
            <img src={cat0} ref={catRef} className='cat animate__backInRight' onClick={play} />
            {
                showNow ? (
                    <div>
                        <p ref={lyricDisplayRef} className='lyric-display'>(Music~)</p>
                        <p ref={lyricTranslatedDisplayRef} className='lyric-display lyric-translated'>(音乐~)</p>
                        <div ref={backgroundRef} className='background'>
                            <div ref={backgroundTextRef} className='background-text'></div>
                        </div>
                    </div>
                ) : (
                    <>
                        <br />
                        <button className="show-btn" onClick={play}>Ready to GO!!</button>
                        <p><i style={{color: 'red'}}>(Click this 👆)</i></p>
                    </>
                )
            }
        </div>
    )
}

export default ShowTime
