let host = ''
// let host = 'http://localhost:8080'

export async function list(): Promise<{ list: List }> {
    const response = await fetch(host + '/api/list');
    const data = await response.json();
    return data as {
        list: List
    }
}

export type List = ListItem[]

export interface ListItem {
    id: number
    githubUrl: string
}

export async function create(githubUrl: string) {
    const response = await fetch(host + '/api/create', {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({ url: githubUrl }),
    });
    const data = await response.json();
    return data as {
        id: number
        status: number
    }
}

export async function getShow(id: number) {
    const response = await fetch(host + `/api/show/${id}`);
    const data = await response.json();
    return data as {
        status: number,
        freestyle: Freestyle,
        error: string,
    }
}

export interface Freestyle {
    musicUrl: string
    lyricsLines: {
        translated: string
        words: {
            word: string;
            start: number;
            end: number;
        }[];
        text: string;
        start: number;
        end: number;
    }[]
}
