import React, { useEffect } from 'react';
import * as api from './api'
import './PrepRoom.css'
import QRPng from './qr.png'

export interface Props {
    setFreestyle: (freestyle: api.Freestyle | null) => void
}

function PrepRoom(props: Props) {
    const [input, setInput] = React.useState<string>('')
    const [error, setError] = React.useState<string>('')

    const handleInput = (e: any) => {
        setInput(e.target.value)
        setError('')
    }

    const [generatingId, setGeneratingId] = React.useState(0)
    const timer = React.useRef<NodeJS.Timeout | null>(null)
    useEffect(() => {
        if (generatingId <= 0) {
            return
        }
        timer.current = setInterval(async () => {
            const res = await api.getShow(generatingId)
            if (res.status === 0) {
                return
            }
            if (res.status === 1) {
                props.setFreestyle(res.freestyle)
            }
            if (res.status === 2) {
                setError(res.error)
            }
            setGeneratingId(0)
            clearInterval(timer.current!)
            timer.current = null
        }, 1000)
        return () => {
            if (timer.current) {
                clearInterval(timer.current)
                timer.current = null
            }
        }
    }, [generatingId])

    const [list, setList] = React.useState([] as api.List)
    useEffect(() => {
        (async () => {
            const res = await api.list()
            setList(res.list)
        })()
    }, [])

    const submit = async () => {
        const url = input
        setGeneratingId(-1)
        const result = await api.create(url)
        setGeneratingId(result.id)
        props.setFreestyle(null)
    }

    const clickHistory = async (id: number, githubUrl: string) => {
        setGeneratingId(id)
        setInput(githubUrl)
    }

    return (
        <div className="PrepRoom">
            <div style={{
                display: 'flex', justifyContent: 'center', alignItems: 'center', backgroundColor: 'black',
                padding: '10px 0',
                marginBottom: '80px',
            }}>
                <img src={QRPng} style={{width: '100px'}} />
                <h2 style={{color: 'white', marginLeft: '10px', wordWrap: 'break-word' }}>demo.bennhuang.com</h2>
            </div>

            <h1 className="title">Copilot-for-Demo</h1>
            <p className="slogan">Your Copilot for Hackathon DEMO phase</p>

            <div className='input-area'>
                <p><b className="slogan">Tell the copilot your GitHub project link to prepare a demo presentation. </b></p>
                <input type="text" className="search-input" placeholder="Your Github Repo Link..."
                    value={input} onChange={handleInput}
                />
                {
                    generatingId === 0 && (
                        <button className="search-btn" onClick={submit}>Generate</button>
                    )
                }
            </div>

            {
                generatingId === 0 && (
                    <div className='history'>
                        <p>Recent history:</p>
                        <ul>
                            {
                                list.map((item) => {
                                    return (
                                        <li key={item.id} onClick={() => clickHistory(item.id, item.githubUrl)} >
                                            {item.githubUrl}
                                        </li>
                                    )
                                })
                            }
                        </ul>
                    </div>
                )
            }
            {
                generatingId !== 0 && (
                    <div className='loading-area' >
                        <div className="loader"></div>
                        <p>Preparing, please wait 40 seconds...</p>
                    </div>
                )
            }
            {
                error && (
                    <div style={{color: 'red'}}>
                        <p>{error}</p>
                    </div>
                )
            }
        </div>
    );
}

export default PrepRoom
